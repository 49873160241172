
import React from 'react';
import { render } from 'react-dom';
import { baseURL } from '../API/url';
import Profile from './profileAdvisor';
import Clients from './portfolioAdvisor';
import Login from './loginAdvisor';
import Register from './registerAdvisor';
import ChangeProfile from './changeProfile'
import '../App.css';
import NavBar from './NavBar/NavBar';
import SideBar from './SideBar/SideBar';


import {
  	//HashRouter as Router,
	BrowserRouter as Router,
  	Route,
  	Link,
  	Switch,
  	Redirect
} from 'react-router-dom'
import ResetPassword from './resetPassword';
import axios from 'axios';
import { Layout, Menu, Typography } from 'antd';
import Icon from 'antd/lib/icon';
import logo from '../image/ginkgo_logo.jpg';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu,Item } = Menu;

class App extends React.Component<any,any> {


	constructor(props:any) {
		super(props);
		this.state = { isAuthenticated: false , currentUser: '' };
	}

	componentDidMount = async() =>{	
		
		const isAuthenticated = await this.checkAuth()
		await this.setState({isAuthenticated: !!isAuthenticated, currentUser: isAuthenticated.email });	
	}

	checkAuth = async() =>{
		try {
			const isAuthenticated = await axios.get(baseURL+"/auth/isAuthenticated");
			return isAuthenticated.data;
		} catch (error) {
			return false;
		}
	}
	
	setAuthenticate = (bool:boolean) => {
		this.setState({isAuthenticated: bool });
	}


	setCurrentUser = (email: string) => {
		this.setState({currentUser: email });
	}

	Menu = () =>(
		<Menu
		className="nav"
		defaultOpenKeys={['0', '1']}
		mode="inline"
		//mode="horizontal"
	>
		<SubMenu key="0" title={
			<span><i className="anticon anticon-home" /> Home</span>
		}>
			<Item key="0.0">
				<Link to='/login' replace ><i className="anticon anticon-home"/> Login</Link>
			</Item>
		</SubMenu>	


{
	this.state.isAuthenticated &&
	<Menu.Item key="1">
		<Link to="/clients" replace ><i className="anticon anticon-home"/> Clients</Link>
	</Menu.Item>
}

{
	this.state.isAuthenticated &&
	<Menu.Item key="5">
		<Typography.Link href="https://ginkgomic.com/investor/events/" target="_blank"><i className="anticon anticon-home"/> News</Typography.Link>
	</Menu.Item>
}

{
	this.state.isAuthenticated &&
		<SubMenu key="2" title={
			<span><i className="anticon anticon-team" /> Forms</span>
		}>
			<SubMenu key="2.2" title={
				<span><i className="anticon anticon-team" />Ginkgo</span>
			}>
				<Item key="2.2.0">
				<	Typography.Link href="https://bit.ly/3CAwMA8" target="_blank"><i className="anticon anticon-home"/>Distribution Option</Typography.Link>
				</Item>
				<Item key="2.2.1">
				<	Typography.Link href="https://bit.ly/3yPOsH1" target="_blank"><i className="anticon anticon-home"/>Redemption</Typography.Link>
				</Item>
				<Item key="2.2.2">
				<	Typography.Link href="https://ginkgomic.sharepoint.com/:b:/g/Ecc8WELBfltEjuLdFBv84EsBEskRTpOYertjDO36Z-gALw?e=M5fkGj" target="_blank"><i className="anticon anticon-home"/>OM</Typography.Link>
				</Item>
			</SubMenu> 

{/*				<Item key="2.0">
				<	Typography.Link href="https://ias.olympiatrust.com/forms" target="_blank"><i className="anticon anticon-home"/>Olympia Trust Company</Typography.Link>
				</Item>
{/* 				<Item key="2.1">
				<	Typography.Link href="https://www-us.computershare.com/Investor/#Help/PrintableForms" target="_blank"><i className="anticon anticon-home"/>Computershare</Typography.Link>
				</Item>
 */}		</SubMenu> 
}

{/* {
	this.state.isAuthenticated &&
		<SubMenu key="2" title={
			<span><i className="anticon anticon-team" /> Report</span>
		}>
				<Item key="2.0">
				<	Typography.Link href="https://rsp.olympiatrust.com/forms.html" target="_blank"><i className="anticon anticon-home"/>Monthly Statement</Typography.Link>
				</Item>
				<Item key="2.1">
				<	Typography.Link href="https://www-us.computershare.com/Investor/#Help/PrintableForms" target="_blank"><i className="anticon anticon-home"/>Tax Slip</Typography.Link>
				</Item>
		</SubMenu> 
}
 */}
{
	this.state.isAuthenticated &&
		<SubMenu key="3" title={
			<span><i className="anticon anticon-team" /> Settings</span>
		}>
			<Item key="3.0">
				<Link to={'/profile'} replace ><i className="anticon anticon-home"/>Profile</Link>
			</Item>
{/* 							<Item key="3.1">
				<i className="anticon anticon-home"/>Change Password
			</Item>
*/}						</SubMenu> 
}
	

{
	this.state.isAuthenticated &&
	<Menu.Item key="4">	
		<a onClick={ async ()=>{
			this.setState({isAuthenticated: false });
			axios.defaults.headers.common['Authorization'] = '';
			await axios.post(baseURL+"/auth/logout", this.state.data, { withCredentials: true }); 
			window.location.href = '/';
		}}>						
		<i className="anticon anticon-home" /> Logout
		</a>
	</Menu.Item>
}

	</Menu>
	);

	render() {
		return (
			<Router>
			<NavBar menu={this.Menu()} />
     		<Layout>
       			 <SideBar menu={this.Menu()} />


				<Layout >
					<Content style={{ margin: '0 16px' }}>
						<Switch>
							<Route exact path="/" >
				                      <Redirect to="/login" /> 
				            </Route>
							<Route exact path={'/login'} component={() => <Login setAuthenticate={this.setAuthenticate} setCurrentUser={this.setCurrentUser} isAuthenticated={this.state.isAuthenticated}/>} />		
							<Route exact path={'/register'} component={Register} />
 							<Route exact path={'/profile'} component={() => <Profile isAuthenticated={[this.state.isAuthenticated, this.state.currentUser]}/>} />
							<Route exact path={'/clients'} component={() => <Clients isAuthenticated= {[this.state.isAuthenticated, this.state.currentUser]} />} />
 							<Route exact path={'/resetPassword'} component={ResetPassword} />
							<Route exact path={'/changeProfile'} component={ChangeProfile} />
						</Switch>
					</Content>						
				</Layout>				
			</Layout>
			</Router>
	)}
}
 
export default App; 

